#map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s ease;
}
#player {
    position: relative;
    z-index: 2;
}
.map-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: grab;
}