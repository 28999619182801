@import '../../scss/variables.scss';


.details {
    color: var(--text);
    h1, h2, h3, h4, h5, h6 {
        color: var(--train-headline);
    }
    .veloroute-section-details {
        h1, h2, h3, h4, h5, h6 {
            color: var(--bike);
        }
    }
}
.details{
    width: 25vw;
    @media (min-width: $breakpoint-l) {
        width: 20vw;
    }
    pointer-events: all;
    .details-headline{
        display: flex;
        gap: .15em;
    }
    h5 + p {
        margin-top: -.5em;
    }
    position: relative;
    padding: 0 $padding-l;
    .devider{
        margin-left: 2px;
        color: #999;
    }
    
    .icon {
        width: 22px;
        height: 22px;
        padding: 3px;
        background: $background-dark;
        border-radius: 3px;
        margin-right: 5px;
        img {
            vertical-align: top;
        }
    }
}