@import '../../../scss/variables.scss';
@import '../../../scss/mixins/caret.scss';

.startdestination {
    .selectwrapper {
        position: relative;
        margin-top: $padding-xs;
        
        &::before {
            @include caret();

            content: '';
            pointer-events: none;
            position: absolute;
            top: 40%;
            right: 0.35em;
            transform: scale(0.55) translateX(-50%);
        }
    }
    select#startdest {
        cursor: pointer;
        color: var(--secondary);
        background: var(--dropdown-bg);
        width: 100%;
        appearance: none;
        border-radius: var(--border-radius);
        min-width: calc( 3em + 0.5vw );
        padding: $padding-xs $padding-xl $padding-xs $padding-l;
        border: none;
        box-shadow: var(--dropdown-box-shadow);
    }
}