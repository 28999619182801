@import '../../../scss/variables.scss';

#header {
    width: 100%;
    padding: $padding-l $padding-l;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    position: relative;
    pointer-events: none;
}
.home {
    #header {
        #language-picker {
            left: 50%;
            transform: translate(-50%, 1em);
            position: absolute;
            width: auto;
        }
    }
}
