@import '../../../scss/variables.scss';

#germany {
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--bg);
    img {
        width: 100%;
    }
    #germany-map {
        position: relative;
        z-index: 1;
        #shape,
        #islands {
            * {
                fill: var(--map-fill);
            }
        }
        #lakes {
            * {
                fill: var(--map-river);
            }
        }
        #rivers {
            * {
                stroke: var(--map-river);
                fill: transparent;
            }
        }
        #sea {
            * {
                fill: var(--map-sea);
            }
        }
        #bundeslaender {
            * {
                fill: transparent;
                stroke: var(--map-stroke);
                stroke-dasharray: 2;
            }
        }
        #labels {
            * {
                fill: var(--map-labels);
                font-family: 'Merriweather';
                font-size: 0.75em;
                font-weight: 300;
                text-transform: uppercase;
                pointer-events: none;
                letter-spacing: 0.1em;
            }
        
        }
        #labels-rivers {
            * {
                fill: var(--map-river);
                font-family: $font-family-sans-serif;
                font-size: 0.55em;
                font-weight: 400;
                pointer-events: none;
            }
        
        }
    }
    .map-bg {
        background-image: url('../../../assets/images/germany_bg.png');
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: multiply;
        background-size: cover;
        opacity: 0.5;
    }
}