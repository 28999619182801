@import '../../../scss/variables.scss';

button,
input[type="submit"],
.button {
      appearance: none;
      -webkit-appearance: none;
      border: 0 solid transparent;
      cursor: pointer;
}

input[type="submit"],
.button {
  padding: 0.65em 1.25em;
  align-self: center;
  display: inline-block;
  position: relative;
  padding: .65em .75em;
  background-color: transparent;
  border-radius: var(--border-radius-xl);
  transition: all .3s ease;

  span {
    font-family: 'Oswald', sans-serif;
    letter-spacing: $letterspacing;
    text-transform: uppercase;
    color: var(--secondary);
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.5;
    cursor: pointer;
    transition: all .35s ease;
    display: inline-block;
    background: var(--button-bg);
    padding: $mainpadding $padding-xl;
    border-radius: var(--border-radius-xl);
  }
  &:hover{
    span{
      box-shadow: -1px -1px 5px rgba(0,0,0,0.5), inset 1px 1px 6px rgba(0,0,0,0.15), inset -1px -1px 2px rgba(255,255,255,0.35);
      background: var(--button-bg-hover);
      transition: all .35s ease;
      color: var(--secondary-hover);
    }
  }
}
  

.theme-light {
    input[type="submit"],
    .button {
      
      box-shadow: -10px -10px 20px white, inset 5px 5px 7px rgba(255,255,255,0.75), inset -1px -1px 8px rgba(0,0,0,0.1), 10px 10px 10px rgba(0,0,0,0.065), -1px -1px 1em rgba(0,0,0,0.1);
      
      &:after{
        content: '';
        display: table;
        box-shadow: -1px -1px 3px rgba(0,0,0,0.15), inset 1px 1px 1px rgba(0,0,0,0.15), inset -1px -1px 1px white, 1px 1px 3px white;
        transition: all .3s ease;
        text-decoration: none;
        color: var(--primary-hover);
        position: absolute;
        left: .5em;
        top: .5em;
        width: calc( 100% - 1.15em );
        height: calc( 100% - 1em );
        border-radius: var(--border-radius-xl);
      }
    
      span {
        text-shadow: 1px 1px 0 white;
        box-shadow: -1px -1px 3px rgba(255,255,255,0.9), inset 1px 1px 2px rgba(255,255,255,0.85), inset -1px -1px 2px rgba(0,0,0,0.35);
      }
      
      &:hover{
        span{
          box-shadow: -1px -1px 5px rgba(0,0,0,0.5), inset 1px 1px 6px rgba(0,0,0,0.15), inset -1px -1px 2px rgba(255,255,255,0.35);
          background: rgb(2,0,36);
          background: var(--button-bg-hover);
          transition: all .35s ease;
        }
      }
    }
}