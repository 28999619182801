@import '../../../../scss/variables.scss';

.veloroute-pin-inner{
    fill: var(--bike);
}
.veloroute-pin-text {
    fill: var(--bg);
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 11px;
}
.veloroute-pin-outer {
    filter: drop-shadow( 1px 1px 2px rgba(0,0,0, 0.15));
    fill: var(--bg-light);
}
