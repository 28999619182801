// light theme
$background: #eaedf1;
$background-darker: #dadee3;
$background-lighter: #eff2f5;
$boxshadow: inset -1px -1px 2px rgba(0,0,0,0.15), 
    1px 1px 0 rgba(255,255,255,0.5), 
    -1px -1px 1px rgba(0, 0, 0, 0.1), 
    inset 1px 1px 2px rgba(255,255,255,0.8),
    9px 9px 30px rgba(0,0,0,0.05);
$gray: #5a6367;
$veloroute: #007377;
$veloroute-hover: #0f4749;
$veloroute-muted: $gray;
$xlightgray: #ededed;
$lightgray: #9ea9b0;
$darkgray: #2b2f32;
$primary: red;
$primary-hover: red;
$primary-active: #5c003d;
$primary-muted: rgb(194, 191, 195);
$primary-hover-muted: pink;

// dark theme
$brown: #382b08;
$brown-light: #4b3909;
$gold-dark: #be9b3b;
$gold: #c5b37b;
$gold-light: #efe6ca;
$gold-xlight:rgb(252, 250, 244);
$background-dark: #181f24;
$xlightblue:#65777f;
$lightblue: #2e3c46;
$dark-lighter: #2c3438;
$dark-darker: #13191c;
$borderradius-light: 6px;
$borderradius-dark: 0;
$borderradius-xs-light: 4px;
$borderradius-xs-dark: 0;
$map-dark-bg: #1f292f;

$themes: (
    light: (
        'border-radius': $borderradius-light,
        'border-radius-xl': $borderradius-light * 4,
        'text': $darkgray,
        'text-active': $gray,
        'text-inactive': $lightgray,
        'bg': $background,
        'bg-light': $background-lighter,
        'primary': $primary,
        'primary-hover': $primary-hover,
        'secondary': $gray,
        'secondary-hover': $primary,
        'muted': $xlightgray,
        'train': $primary,
        'train-hover': $primary-hover,
        'train-active': $primary-active,
        'train-muted': $lightgray,
        'train-headline': $primary-active,
        'train-details': $primary-hover,
        'bike': $veloroute,
        'bike-hover': $veloroute-hover,
        'bike-active': $veloroute-hover,
        'bike-inactive': $lightgray,
        'bike-muted': $veloroute-muted,
        'bike-details': $veloroute,
        'primary-muted': $primary-muted,
        'linklist-bg': $xlightgray,
        'linklist-bg-active': white,
        'linklist-border-radius': none,
        'linklist': $gray,
        'linklist-active': $veloroute,
        'label': $gray,
        'label-active': $primary,
        'label-inactive': $lightgray,
        'label-bg': transparent,
        'label-active-bg': transparent,
        'input-border': transparent,
        'logo-color': $darkgray,
        'button-bg': linear-gradient(180deg, $background-lighter 0%, $background-darker 100%),
        'button-bg-hover': linear-gradient(180deg, $background-darker 0%, $xlightgray 70%), 
        'dropdown-bg': linear-gradient(0deg, $background-darker, transparent),
        'dropdown-bg-hover': linear-gradient(0deg, $background-darker, transparent),
        'dropdown-box-shadow': $boxshadow,
        'map-fill': $background-lighter,
        'map-stroke': $background-darker,
        'map-river': #cae4ef,
        'map-labels': $background-darker,
        'map-sea': #dae7ea,
    ),
    dark: (
        'border-radius': $borderradius-dark,
        'border-radius-xl': $borderradius-dark,
        'text': $gold,
        'text-active': $gold-light,
        'text-inactive': $brown-light,
        'bg': $background-dark,
        'bg-light': $brown,
        'primary': $gold-dark,
        'primary-hover': $gold,
        'secondary': $gold-light,
        'secondary-hover': $gold-xlight,
        'muted': $gray,
        'train': $gold-dark,
        'train-hover': $gold,
        'train-active': $gold-dark,
        'train-muted': $brown-light,
        'train-headline': $gold-dark,
        'train-details': $gold-dark,
        'bike': $gold,
        'bike-hover': $gold-light,
        'bike-active': $gold-dark,
        'bike-inactive': $brown-light,
        'bike-muted': $brown-light,
        'bike-details': $gold-light,
        'primary-muted': $lightblue,
        'linklist-bg': $dark-darker,
        'linklist-bg-active': $gold,
        'linklist-border-radius': $borderradius-dark,
        'linklist': $gold-light,
        'linklist-active': white,
        'label': $lightblue,
        'label-active': $gold-light,
        'label-inactive': $lightblue,
        'label-bg': $dark-darker,
        'label-active-bg': $xlightblue,
        'label-inactive-bg': $lightblue,
        'input-border': $dark-darker,
        'logo-color': $gold,
        'button-bg': $lightblue,
        'button-bg-hover': $gold-dark,
        'dropdown-bg': $lightblue,
        'dropdown-bg-hover': $gold-dark,
        'dropdown-box-shadow': none,
        'map-fill': $map-dark-bg,
        'map-stroke': $background-dark,
        'map-river': $background-dark,
        'map-labels': $background-dark,
        'map-sea': transparent,
    )
);


$boxshadow: 8px 8px 8px rgba(0,0,0,0.3);

$sidebarwidth: 22.5em;

$scalefactor: .5vw;

// SIZING
$borderradius: .5em;
$mainpadding: calc(#{$scalefactor} + 0.5em);
$padding-xxl: calc( #{$scalefactor} + 2.5em);
$padding-xl: calc( #{$scalefactor} + 1.5em);
$padding-l: calc( #{$scalefactor} + 1em);
$padding-s: calc( #{$scalefactor} + 0.25em);
$padding-xs: calc( #{$scalefactor} + 0.1em);


// FONT SIZES
$font-family-sans-serif:                         'Source Sans Pro', sans-serif;
$font-family-display:                            'Oswald', sans-serif;
$global-font-family:                             $font-family-sans-serif;
$base-heading-font-family:                       $font-family-display;

$base-line-height:                               1.5;

$base-font-size:                                 1em;
$global-font-size:                               $base-font-size;
$h1-font-size:                                   calc( #{$base-font-size} * 1.5 + #{$scalefactor} );
$h2-font-size:                                   calc( #{$base-font-size} * .95 + #{$scalefactor} );
$h3-font-size:                                   calc( #{$base-font-size} * .8 + #{$scalefactor} );
$h4-font-size:                                   calc( #{$base-font-size} * .7 + #{$scalefactor} );
$h5-font-size:                                   $base-font-size * .8;
$h6-font-size:                                   $h5-font-size;

$letterspacing:                                  0.03em;

// MEDIA QUERIES
$breakpoint-l: 1680px;
$breakpoint-xl: 1920px;
$breakpoint-s-max: 767px;

// Checkboxes
$checkBoxRadioActiveColor: rgba(225, 225, 225, 0.4);
$checkBoxRadioColor: rgba(240,240,240,0.4);
$checkBoxBorderradius: .4em;