@import '../../../../scss/variables.scss';

.veloroute-stop{
    fill: var(--bike);
    transform: scale(1);
    &.hover {
        transform: scale(1.8);
    }
    &.active {
        fill: var(--bike-hover);
    }
}
.veloroute.destinationLabel{
    tspan {
        fill: var(--bike-hover); 
    }
}

circle.veloroute-stop-outline {
    stroke: var(--bike-hover);
    fill: var(--bg);
}