@import '../../../../scss/variables.scss';

.spot{
    fill: var(--train);
    transition: transform .35s ease, fill .35s ease;
    &.spot-large{
        transition: all .35s ease, fill .35s ease;
        opacity: 0;
        transform: scale(1);
    }
    &.spot-small{
        transform: scale(0);
    }
}
.spot-bg {
    fill: transparent;
}
.spotgroup {
    &.veloroutestop {
        .spot {
            transition: transform .35s ease, fill .35s ease; 
            &.spot-small{
                transform: scale(1)!important;
                fill: var(--train);
            }
        }
    }
    &.sectionstop {
        .spot {
            transition: transform .35s ease, fill .35s ease; 
            &.spot-small{
                transition: all .35s ease, fill .35s ease; 
                transform: scale(1)!important;
                fill: var(--train);
            }
        }
    }
}

.routegroup {
    &.inactive {
        .spot {
            transition: transform .35s ease, fill .35s ease; 
            &.spot-small{
                transform: scale(0.8)!important;
                fill: var(--train-muted);
            }
            &:hover {
                .spot {
                    transition: transform .35s ease, fill .35s ease; 
                    &.spot-small{
                        transform: scale(1)!important;
                        fill: var(--train-hover);
                    }
                }
            }
        }
    }
    &.active {
        .spot {
            transition: transform .35s ease, fill .35s ease; 
            &.spot-small{
                transform: scale(1)!important;
                fill: var(--train-active);
            }
        }
    }
    &:hover {
        .spot {
            transition: transform .35s ease, fill .35s ease; 
            &.spot-small{
                transform: scale(1)!important;
                fill: var(--train-hover);
            }
        }
    }
}


g.destination:hover {
    .spot {
        transition: transform .35s ease, fill .35s ease; 
        &.spot-large{
            fill: var(--train-hover);
            opacity: 0;
            animation: circleHoverAni .5s ease-in-out forwards;
        }
        &.spot-small{
            transform: scale(1.2);
            fill: var(--train-hover);
        }
    }
}