@import '../../../scss/variables.scss';

$checkbox-width: 1.75em;
$checkBoxBorderradius: .4em;
$transition-duration: .5s;

input[type="checkbox"] {
    -webkit-appearance: none;
}

.directconnection {
    svg.check {
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        &.active {
            transition: all $transition-duration ease;
        }
        transition: all $transition-duration ease;
    }

    .inputCheckbox {
        width: $checkbox-width;
        height: $checkbox-width;
        position: relative;
        margin-top: .5em;
    }
    
    label{
        display: block;
    }
    input[type="checkbox"] {
        width: 100%;
        height: 100%;
    }
}

.theme-light {
    .checkBoxRadioNotActive {
        background-color: $checkBoxRadioColor;
        transition: box-shadow $transition-duration ease, background-color $transition-duration ease;
        box-shadow: -6px -6px 10px rgba(255,255,255,1), inset 1px 1px 1px rgba(255,255,255,0.9), inset -1px -1px 1px rgba(0,0,0, 0.1), 5px 5px 10px rgba(0,0,0, .1);
    }
    .checkBoxRadioActive {
        background-color: $checkBoxRadioActiveColor;
        transition: box-shadow $transition-duration ease, background-color $transition-duration ease;
        box-shadow: -1px -1px 2px rgba(255,255,255,0.1), inset 4px 4px 8px rgba(0, 0, 0, 0.15), inset -1px -1px 1px rgba(0,0,0, 0), 1px 1px 0 rgba(255,255,255, 1);
    }

    .directconnection {
        .inputCheckbox {
            border-radius: $checkBoxBorderradius;
            &.active {
                @extend .checkBoxRadioActive;
            }
            &:not(.active){
                @extend .checkBoxRadioNotActive;
            }
        }
        svg.check {
            filter: grayscale(1);
            opacity: .3;
            &.active {
                filter: grayscale(0);
                opacity: 1;
            }
        }
    }
}
  
.theme-dark {
    
    .directconnection {
        .inputCheckbox {
            background-color: var(--label-inactive-bg);
            &.active {
                background-color: var(--label-active-bg);
            }
        }
        svg.check polyline {
            stroke: var(--secondary)!important;
        }
    }
}
