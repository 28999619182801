@import './webfonts.css';
@import './scss/variables.scss';

html{
  font-size: 16px;
  @media (min-width: $breakpoint-xl) {
    font-size: 18px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}
body{
  font-family: $global-font-family;
  font-size: $global-font-size;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $base-heading-font-family;
}

h1, h2, h3 {
  line-height: 1.3;
}

h2, h3 {
  font-size: $h2-font-size;
  margin-bottom: .33em;
  color: var(--text);
  font-family: $global-font-family;
  font-weight: 400;
}

h3 {
  font-size: $h3-font-size;
  margin-bottom: 0;
}

h5 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: $letterspacing;
  color: var(--text);
  font-size: $h5-font-size;
  margin-bottom: .75em;
  margin-top: 1em;
}

h6 {
  font-size: $h6-font-size;
  color: var(--text);
  margin-top: .75em;
}

label {
  font-family: $base-heading-font-family;
  font-size: 75%;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: $letterspacing;
  color: var(--label);
}

header {
  margin-bottom: $padding-xs;
}
.section{
  margin-bottom: $mainpadding;
  p {
      margin-top: -.3rem;
      font-size: 1rem;
  }
  &.d-flex {
      display: flex;
  }
  ul li {
      list-style-type: none;
      list-style-type: none;
  }
} 

a {
  color: var(--text);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.margintop {
  margin-top: 2em;
}

.container {
  text-align: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

span.train {
  display: inline-block;
  background-color: var(--bg-light);
  border-radius: $borderradius / 2;
  padding: 1px 3px;
  font-family: $global-font-family;
  font-weight: 700;
  color: var(--train-headline);
  font-size: .75rem;
  margin: 0 5px 0 0;
  vertical-align: top;
  white-space: nowrap;
  &.connection {
    background-color: transparent;
    padding: 2px 0;
  }
}
.train-connection {
  white-space: nowrap;
}
