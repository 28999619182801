@import '../../../scss/variables.scss';

$iconsize: calc( #{$scalefactor} + 1.5em);

.destinationslist {
    li {
        position: relative;
        background: var(--linklist-bg);
        border-radius: var(--linklist-border-radius);
        box-shadow: 3px 3px 8px rgba(0,0,0,0.1); 
        padding: 0.25em;
        margin: $padding-xs 0;
        transition: all .35s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: $padding-s;
        .velo-icon{
            width: $iconsize;
            height: $iconsize;
            flex-shrink: 0;
            rect.bg{
                fill: var(--bike-inactive);
                transition: all .35s ease;
            }
        }
        h4.veloroute {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            color: var(--linklist);
            line-height: 1.2;
        }
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border-bottom: 10px solid transparent;
            border-top: 10px solid transparent;
            border-left: 0em solid white;
            transition: all .35s ease;
        }
        &.active,
        &:hover {
            transition: all .35s ease;
            background: var(--linklist-bg-active);
            box-shadow: 5px 3px 10px rgba(0,0,0,0.15); 
            h4.veloroute {
                color: var(--linklist-active);
            }
            
            .velo-icon{
                rect.bg{
                    fill: var(--bike-active);
                    transition: all .35s ease;
                }
            }
        }
        &.active {
            h4.veloroute {
                color: var(--linklist-active)!important;
            }
            &:before {
                width: 0; 
                height: 0; 
                right: -.5em;
                border-top: .5em solid transparent;
                border-bottom: 10px solid transparent;
                border-left: .5em solid var(--linklist-bg-active);
                transition: all .35s ease;
            }
        }
        &.nomatch {
            padding: 0;
            background: transparent;
            box-shadow: none; 
            cursor: default;
        }
    }
    
    .stations {
        display: flex;
        font-size: .85em;
    }
}