@import '../../scss/variables.scss';

#travelduration {
    position: relative;
    display: flex;
    padding: 0 $padding-l;
    gap: $padding-xl;
    padding: 0.5em $mainpadding;
    input {
        align-self: center;
    }
}
.traveldurationWrapper {
    width: 100%;
    display: flex;
    gap: $padding-xl;
    padding: $padding-s $padding-xl;
    position: relative;
    -webkit-appearance: none;
    backdrop-filter: blur(3px);
}

.theme-light {
    .traveldurationWrapper {
        background: rgba(var(--bg),.6);
        // background: linear-gradient(180deg, var(--bg-light) 0%, var(--bg) 90%);
        background: linear-gradient(180deg, var(--bg-light) 0%, var(--bg) 90%);
        transition: all .25s ease;
        border-radius: $borderradius * 2;
        box-shadow: -10px -10px 30px white, -5px -5px 10px white, 10px 10px 30px rgba(0, 0, 0, 0.25);
        text-shadow: 1px 1px 0 white;
        transition: all .3s ease;
    }
}
.theme-dark {
    .traveldurationWrapper {
        background: var(--linklist-bg);
    }
}

fieldset {
    border: none;
}




