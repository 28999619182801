.loading {
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    #loader {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: relative;
    }
    p {
        text-align: center;
    }
}