@import '../../../scss/variables.scss';

$strokeAniDuration: .35s;
$maxspotscale: 2.5;
$midspotscale: 1.5;
$transitionSpeed: .35s;


@keyframes strokeAni {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes circleHoverAni {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    40% {
        opacity: .5;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}

svg#routes{
    position: relative;
    z-index: 2;

    polyline,
    circle,
    g{
        cursor: pointer;
    }
    .routegroup{
        polyline.route{
            fill: none;
            stroke: var(--train);
            transition: all .5s ease;
            opacity: 1;
            animation: strokeAni 3s linear forwards;
            animation-delay: .5s;
        }
        polyline.route-bg {
            fill: none;
            stroke: transparent;
        }
        &.active {
            polyline.route {
                stroke: var(--train-active);
                animation: none;
                stroke-dashoffset: 0!important;
                transition: all .5s ease;
            }
        }
        &.inactive {
            .route-bg{
                stroke: transparent;
                opacity: .5;
                fill: none;
            }
            .route {
                transition: all .5s ease;
                opacity: 1;
                stroke: var(--train-muted);
                fill: none;
            }
        }
        &:hover {
            .route {
                stroke: var(--train-hover);
            }
        }
    }
}
