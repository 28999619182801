/* source-sans-pro-regular - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/webfonts/source-sans-pro-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/webfonts/source-sans-pro-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/webfonts/source-sans-pro-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/webfonts/source-sans-pro-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/webfonts/source-sans-pro-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/webfonts/source-sans-pro-v18-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }

  /* oswald-300 - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/webfonts/oswald-v41-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/webfonts/oswald-v41-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/webfonts/oswald-v41-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/webfonts/oswald-v41-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/webfonts/oswald-v41-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/webfonts/oswald-v41-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
  }
  /* oswald-700 - latin */
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/webfonts/oswald-v41-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/webfonts/oswald-v41-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/webfonts/oswald-v41-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/webfonts/oswald-v41-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/webfonts/oswald-v41-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/webfonts/oswald-v41-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
  }
  /* latin-ext */
  @font-face {
     font-family: 'Merriweather';
     font-style: normal;
     font-weight: 300;
     font-display: swap;
     src: url('./assets/webfonts/u-4n0qyriQwlOrhSvowK_l521wRZVsf6hPvhPUWH.woff2') format('woff2');
     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
   }
  /* latin-ext */
@font-face {
     font-family: 'Merriweather';
     font-style: normal;
     font-weight: 700;
     font-display: swap;
     src: url('./assets/webfonts/u-4n0qyriQwlOrhSvowK_l52xwNZVsf6hPvhPUWH.woff2') format('woff2');
     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
   }
   