@import 'variables';
@import './mixins/caret.scss';
@import './icons/icons.scss';

@each $name, $map in $themes {
    .theme-#{$name} {
        @each $key, $value in $map {
            --#{$key}: #{$value};
        }
    }
} 