@import '../../../scss/variables.scss';
@import '../checkBox/checkbox.scss';
@import '../../stateless/button/button.scss';

$radiobtn-height: 2.2em;
$radiobtn-width: 2.2em;
$radioTransitionSpeed: .35s;
$langSwitcherPadding: 0.4em;
$langSwitcherBorderRadius: 2em;

form.switcher {
    pointer-events: all;
    align-self: flex-start;
    padding: 0.15em;
}
form.switcher fieldset {
    display: flex;
    position: relative;
    height: $radiobtn-height;
    &:after,
    &:before {
        content: '';
        display: table;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all .5s ease;
    }
    &:before {
        z-index: 2;
    }
    // &.val-1 {
    //     .inputRadiowrapper:first-child{
    //         label{
    //             color: var(--primary);
    //             fill: var(--primary);
    //             * {
    //                 fill: var(--primary);
    //             }
    //         }
    //     }
    // }
    // &.val-2 {
    //     .inputRadiowrapper:nth-child(2){
    //         label{
    //             color: var(--primary);
    //             fill: var(--primary);
    //             * {
    //                 fill: var(--primary);
    //             }
    //         }
    //     }
    // }
    .inputRadiowrapper{
        label{
            transition: all .5s ease;
        }
    }
    .inputRadiowrapper {
        position: relative;
        z-index: 2;
        transition: all $transition-duration ease;
        width: $radiobtn-width;
        height: $radiobtn-width;
        label {
            color: var(--label-inactive);
            fill: var(--label-inactive);
            * {
                fill: var(--label-inactive);
            }
            position: absolute;
            top: 50%;
            left: 50%;
            text-shadow: none;
            text-align: center;
            cursor: pointer;
            transition: all .5s ease;
        }
        &.active {
            input {
                background: var(--label-active-bg);
            }
            label {
                color: var(--label-active);
                fill: var(--label-active);
                * {
                    fill: var(--label-active);
                }
            }
        }
    }
    input[type="radio"],
    input[type="checkbox"] {
        display: block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        padding: $padding-xs;
        height: 100%;
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: var(--label-bg);
        border: 4px solid var(--input-border);
    }
}

.theme-light {
    form.switcher {
        border-radius: $langSwitcherBorderRadius;
        background-color: var(--muted);
        box-shadow: 0 0 1px #fff, 0 0 2px #fff, 5px 8px 15px rgba(0,0,0,0.1), -5px -3px 15px white;
    }
    form.switcher fieldset {
        border-radius: $langSwitcherBorderRadius;
        
        &:after,
        &:before {
            border-radius: $langSwitcherBorderRadius;
        }
        &:after {
            background: linear-gradient(90deg, #cecece 0%, white 70%);
        }
        &.val-1 {
            &:after {
                box-shadow: 1px 1px 1px white, inset 1px 1px 2px white, -1px -1px 2px rgba(0,0,0,0.15), inset -6px 0px 1px rgba(0,0,0,0.2), inset -1px -1px 2px rgba(0,0,0,0.2), inset -1px 1px 8px rgba(0,0,0,0.05);
            }
            &:before {
                background: rgba(255,255,255,0.35);
            }
            .inputRadiowrapper:first-child{
                label{
                    transform: translate(-40%, -50%);
                }
            }
            .inputRadiowrapper:nth-child(2){
                label {
                    transform: translate(-100%, -50%);
                }
            }
        }
        &.val-2 {
            &:after {
                box-shadow: 1px 1px 1px white, inset 1px 1px 2px white, -1px -1px 2px rgba(0,0,0,0.15), inset 6px 0px 1px rgba(255,255,255,0.9),  inset -1px -1px 2px rgba(0,0,0,0.2), inset 1px 0px 8px rgba(0,0,0,0.05);
            }
            &:before {
                background: rgba(255,255,255,0);
            }
            .inputRadiowrapper:first-child{
                label{
                    transform: translate(0%, -50%);
                }
            }
            .inputRadiowrapper:nth-child(2){
                label{
                    transform: translate(-50%, -50%);
                }
            }
        }
    }


    .inputRadiowrapper {
        label {
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: $letterspacing;
            font-size: .75em;
            text-shadow: 1px 1px 1px white;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        padding: $padding-xs;
        font-weight: 600;
        font-size: .8rem;
        border-radius: $checkBoxBorderradius;
    }
}


.theme-dark {
    form.switcher {
        fieldset {
            .inputRadiowrapper {
                label {
                    transform: translateY(-50%);
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
}