@import '../../scss/variables.scss';
@import '../../scss/mixins/caret.scss';

$sidebarwidth: 25;
$sidebarwidth-xl: 20;
$transition: width .5s ease;

#wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    height: 100%;
    overflow: hidden;
}
#container {
    width: 3*$sidebarwidth + 100vw;
    position: relative;
    display: flex;
    justify-content: right;
    flex-grow: 1;
    transition: $transition;
    &[class*='width-'] {
        transition: $transition;
    }
    &.width-1 {width: 1*$sidebarwidth + 100vw}
    &.width-2 {width: 2*$sidebarwidth + 100vw}
    &.width-3 {width: 3*$sidebarwidth + 100vw}
    @media (min-width: $breakpoint-l) {
        width: 3*$sidebarwidth-xl + 100vw;
        &.width-1 {width: 1*$sidebarwidth-xl + 100vw}
        &.width-2 {width: 2*$sidebarwidth-xl + 100vw}
        &.width-3 {width: 3*$sidebarwidth-xl + 100vw}
    }

    &.shift {
        aside > div:first-child {
            width: 0vw;
            transition: $transition;
        }
    }
}
main {
    position: relative;
    flex-grow: 1;
    order: 1;
    display: flex;
    flex-direction: column;
}
#map-wrapper {
    flex-grow: 1;
    position: relative;
}
aside {
    z-index: 10;
    order: 2;
    pointer-events: none;
    position: relative;
    display: flex;
    width: 3*$sidebarwidth + 0vw;
    @media (min-width: $breakpoint-l) {
        width: 3*$sidebarwidth-xl + 0vw;
    }
    > div {
        @media (min-width: $breakpoint-l) {
            width: $sidebarwidth-xl + 0vw;
        }
        width: $sidebarwidth + 0vw;
        transition: $transition;
    }
}
.instructions {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    &:before {
        content:'';
        position: absolute;
        bottom: -0.75em;
        left: 50%;
        transform: translateX(-50%);
        @include caret();
    }
}