@import '../../../scss/variables.scss';
@import '../../../scss/mixins/caret.scss';

// .caret {
//     width: 0; 
//     height: 0; 
//     border-left: .35em solid transparent;
//     border-right: .35em solid transparent;
//     border-top: .35em solid var(--bike);
// }
.collapse {
    .toggle {
        cursor: pointer;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            margin-left: $padding-xs;
            @include caret(var(--bike));
            top: 50%;
        }
        &.on {
            &:after {
                transform: translateY(-50%) rotate(0deg);
            }
        }
        &.off {
            &:after {
                transform: translateY(-50%) rotate(270deg);
            }
        }
    }
    .toggle-container {
        overflow: hidden;
        &.off {
            height: 0;
        }
    }
}