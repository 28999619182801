@import '../../../scss/variables.scss';

.veloroute {
    path,
    circle,
    g {
        cursor: pointer;
        &:hover,
        &.hover {
            path.veloroute-section {
                stroke-dasharray: 0;
                stroke: var(--bike);
            }
        }
    }
    path.veloroute-section {
        stroke: var(--bike);
        stroke-dasharray: 2;
        fill: none;
        &.active {
            stroke-dasharray: 0;
        }
    }
    path.crossing-veloroute-section {
        stroke: $lightgray;
        stroke-dasharray: 2;
        fill: none;
        &.active {
            stroke-dasharray: 0;
            stroke: var(--bike-hover);
        }
    }
    path.veloroute-section-large {
        stroke: transparent;
        fill: none;
    }
}
.veloroute-details {
    display: block;
    padding: 0;
    .veloroute-stops{
        font-size: 80%;
    }
}

