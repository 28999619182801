@import '../../../scss/variables.scss';

.destinationLabel {
    white-space: nowrap;
    padding: 2px;
    letter-spacing: 0.025em;
    line-height: 1;
    color: var(--primary);
    transition: opacity .5s ease, color .5s ease;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    z-index: 7;
    tspan{
        fill: var(--primary-hover);
        text-shadow: 1px 1px 0 var(--bg), -1px -1px 0 var(--bg);
        filter: drop-shadow(2px 0 1px var(--bg))
    }    
}
g.destination.hover {
    .destinationLabel tspan{
        fill: var(--primary-hover);
    }
}