@import '../../scss/variables.scss';

#home {
    .container {
        @media (max-height: $breakpoint-s-max), (max-width: $breakpoint-s-max) {
            height: 85%;
        }
    }
    .wrapper{
        max-width: calc( 35em + 0.5vw );
        margin: auto;
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        z-index: 2;
        max-height: 90%;
        @media (max-height: $breakpoint-s-max), (max-width: $breakpoint-s-max) {
            overflow: scroll;
            transform: translateY(0);
            top: 0;
        }
    }
    ol {
        list-style: none;
        counter-reset: item;
        text-align: left;
        padding: 1em 5em;
      }
      li {
        counter-increment: item;
        margin-bottom: 0.5em;
        position: relative;
      }
      li:before {
        width: 1.5em;
        height: 1.5em;
        content: counter(item);
        background: var(--primary);
        color: white;
        text-align: center;
        display: inline-block;
        left: -2em;
        top: 0.25em;
        position: absolute;
    }

    p {
        padding: 0 1em;
    }

    p.start {
        padding: 1em;
    }
    
    #germany{
        img {
            width:100%; 
            height:100%;
            object-fit: cover;
        }
        background-size: cover;
        background-position: center;
        width: 100%;
        left: 30%;
        top: 40%;
        transform: translate(-50%,-50%);
    }
}